export const types = {
  set_direct_url: 'set_direct_url',
  set_mx_data: 'set_mx_data',
};

export const initialState = {
  directUrl: '',
  mxData: '',
};

export function reducer(state, action) {
  switch (action.type) {
    case types.set_direct_url: {
      return {
        ...state,
        directUrl: action.payload,
      };
    }
    case types.set_mx_data: {
      return {
        ...state,
        mxData: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};