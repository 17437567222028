import React, { useReducer } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import 'bootstrap/dist/css/bootstrap.min.css';

// Store
import { types, reducer, initialState } from './reducer';
// Styles
import './styles.css';

const domId = 'connect-widget';

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const runMxWidget = (mxWidgetUrl) => {
    const mxConnect = new window.MXConnect({
      id: domId,
      config: {
        mode: 'verification',
        color_scheme: 'light',
        ui_message_version: 4
      },
      onEvent: (type, payload) => {
        console.log('event', type);
        console.log('payload', payload);
        if (type === 'mx/connect/memberConnected') {
          dispatch({
            type: types.set_mx_data,
            payload: payload.member_guid,
          })
        };
      },
    });
    mxConnect.load(mxWidgetUrl);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    runMxWidget(state.directUrl);
  };

  const onChange = (e) => {
    dispatch({ type: types.set_direct_url, payload: e.target.value })
  };

  const copyContent = async (e) => {
    e.preventDefault();
    try {
      await navigator.clipboard.writeText(JSON.stringify(state.mxData, false, 2));
    } catch (error) {
      console.error('Failed to copy: ', error);
    }
  };

  return (
    <div className="main">
      <div className="block">
        <h1 className="title">
          MX Mock Test
        </h1>
        <Form className="form" onSubmit={onSubmit}>
          <Form.Group className="mb-3" controlId="directUrl">
            <Form.Label>
              Direct MX URL
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Pass url"
              value={state.directUrl}
              onChange={onChange}
            />
            <Form.Text className="text-muted">
              Provide direct MX URL for widget launching.
            </Form.Text>
          </Form.Group>
          <div className="app-row">
            <Button
              variant="primary"
              type="submit"
              disabled={!state.directUrl}
            >
              Submit
            </Button>
          </div>
        </Form>
        <div id={domId} />
        {
          !!state.mxData &&
          <>
            <Form.Group className="mb-3" controlId="mxData">
              <Form.Label>
                MX data
              </Form.Label>
              <pre>
                <code className="code">
                  {JSON.stringify(state.mxData, false, 2)}
                </code>
              </pre>
            </Form.Group>
            <Button
              variant="primary"
              onClick={copyContent}
            >
              Copy
            </Button>
          </>
        }
      </div>
    </div>
  );
}

export default App;